import { db } from "../../src/main.js";
import {
  doc,
  getDoc,
  addDoc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
require("firebase/firestore");
import assessmentDetails from "../../src/components/assessmentDetails.json";

export const getAssessmentTemplate = async (id) => {
  const docRef = doc(db, "assessmentTemplates", id);
  const response = await getDoc(docRef);

  if (response.exists()) {
    return response.data();
  } else {
    console.log("error");
  }
};

export const sendAssessmentRequest = async (data) => {
  const response = await addDoc(collection(db, "assessments"), data);
  if (response.id) {
    updateDoc(doc(db, "assessments", response.id), {
      id: response.id,
    });
    return response.id;
  } else {
    console.log("error");
  }
};

export const getAssessmentRequests = async (uid) => {
  const q = query(
    collection(db, "assessments"),
    where("assessorId", "==", uid),
    where("dateCompleted", "==", null)
  );
  const response = await getDocs(q);
  const assessments = [];
  response.forEach((doc) => {
    assessments.push(doc.data());
  });
  return assessments;
};

export const getCompletedAssessmentRequests = async (uid) => {
  const q = query(
    collection(db, "assessments"),
    where("assessedId", "==", uid),
    where("dateCompleted", "!=", null)
  );
  const response = await getDocs(q);
  const assessments = [];
  response.forEach((doc) => {
    assessments.push(doc.data());
  });
  return assessments;
};

export const getAssessmentById = async (id) => {
  const docRef = doc(db, "assessments", id);
  const response = await getDoc(docRef);

  if (response.exists()) {
    return response.data();
  } else {
    console.error("error");
  }
};

export const getRequestedAssessments = async (uid) => {
  const q = query(
    collection(db, "assessments"),
    where("assessorId", "==", uid),
    where("dateCompleted", "==", null)
  );
  const response = await getDocs(q);
  const assessments = [];
  response.forEach((doc) => {
    assessments.push(doc.data());
  });
  return assessments;
};

export const getUsersSelfAssessents = async (uid) => {
  const q = query(
    collection(db, "assessments"),
    where("assessorId", "==", uid),
    where("dateCompleted", "==", null)
  );
  const response = await getDocs(q);
  const assessments = [];
  response.forEach((doc) => {
    assessments.push(doc.data());
  });
  return assessments;
};



export const getExistingAssessmentDetails = async (assessmentId) => {
  const q = query(
    collection(db, "assessmentDetails"),
    where("assessmentId", "==", assessmentId)
  );
  const response = await getDocs(q);
  if (!response.empty) {
    return response.docs[0].data();
  } else {
    return null;
  }
};

export const setAssessmentDateStarted = async (id) => {
  updateDoc(doc(db, "assessments", id), {
    dateStarted: new Date().toISOString(),
  });
};

export const setAssessmentDateCompleted = async (id) => {
  updateDoc(doc(db, "assessments", id), {
    dateCompleted: new Date().toISOString(),
  });
};

export const getAllUsersAssessments = async (uid) => {
  const assessments = [];
  const q1 = query(
    collection(db, "assessments"),
    where("assessedId", "==", uid)
  );

  let response = await getDocs(q1);
  response.forEach((doc) => {
    assessments.push(doc.data());
  });

  return assessments;
};

export const getAllUsersAssessmentRequests = async (uid) => {
  const assessments = [];
  const q1 = query(
    collection(db, "assessments"),
    where("assessorId", "==", uid)
  );

  let response = await getDocs(q1);
  response.forEach((doc) => {
    assessments.push(doc.data());
  });

  return assessments;
};

export const getAllUsersAssessmentDetails = async (uid) => {
  const assessments = [];
  const q1 = query(
    collection(db, "assessmentDetails"),
    where("assessedId", "==", uid)
  );

  let response = await getDocs(q1);
  response.forEach((doc) => {
    assessments.push(doc.data());
  });

  return assessments;
};

export const uploadTemplate = async () => {
  const response = await addDoc(
    collection(db, "assessmentTemplates"),
    assessmentDetails
  );

  if (response.id) {
    return response.id;
  } else {
    console.log("uploadTemplate error");
  }
};

export const createAssessmentDetails = async (assessmentDetails)  => {
  const response = await addDoc(
    collection(db, "assessmentDetails"),
    assessmentDetails
  );

  if (response.id) {
    updateDoc(doc(db, "assessmentDetails", response.id), {
      id: response.id,
    });
    return response.id;
  } else {
    console.log("assessmentDetails error");
  }
}

export const cancelAssessment = async (id) => {
  updateDoc(doc(db, "assessments", id), {
    dateCanceled: new Date().toISOString(),
  });
  return id;
};

export const getAllAssessmentTemplates = async () => {
  const q = query(collection(db, "assessmentTemplates"));
  const response = await getDocs(q);
  const templates = [];
  response.forEach((doc) => {
    templates.push(doc.data());
  });
  return templates;
};

export const getAllAssessments = async () => {
  const q = query(collection(db, "assessments"));
  const response = await getDocs(q);
  const assessments = [];
  response.forEach((doc) => {
    assessments.push(doc.data());
  });
  return assessments;
};
